import { getQueryString, handleError, page_size } from "../apiUtils";
import { createAxiosInstance } from "../apiUtils";

export async function getSources(token: string, query: object, cursor: string | null) {
    const axiosInstance = await createAxiosInstance(token);
    const query_string = getQueryString(query)
    try {
        const resp = await axiosInstance.get("/v2/sources/?cursor=" + (cursor || "") + "&" + query_string);
        return resp.data;
    } catch (err) {
        handleError(err);
    }
}

export async function getSourcesForConnection(token: string, provider_id: string, query: object) {
    const axiosInstance = await createAxiosInstance(token);
    const query_string = getQueryString(query)
    try {
        const resp = await axiosInstance.get("/v2/sources/?pager=limit&limit=" + page_size + "&provider=" + provider_id + "&" + query_string);
        return resp.data;
    } catch (err) {
        handleError(err);
    }
}