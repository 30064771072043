import React, { useState, ChangeEvent } from "react";
import { useParams } from "react-router-dom";
import { useKeycloak } from '@react-keycloak/web';
import { toast } from 'react-toastify';
import { Member, buildMember } from "../common/ObjectTypes";
import MemberModal from "./MemberModal";
import { inviteOrganizationMember } from "../../api/organizations/organizationsApi";
import { saveUserDetails } from "../../api/users/usersApi";

type ManageMemberModalProps = {
    onToggleModal: Function,
    existingMember: Member,
    newMember: boolean,
    currentMember: Member,
    superuser: boolean
}

export function ManageMemberModal({
    onToggleModal,
    existingMember,
    newMember,
    currentMember,
    superuser
}: ManageMemberModalProps) {
    const {keycloak} = useKeycloak();
    const {org_id} = useParams();
    const [member, setMember] = useState<Member>(buildMember())
    const [memberToUpdate, setMemberToUpdate] = useState<Member>(buildMember(existingMember.email, existingMember.first_name, existingMember.full_name, existingMember.last_name, existingMember.role, existingMember.id))

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const item = event.target
        const item_id: string = item.id
        newMember ? 
            setMember((prevMember: Member) => ({
                ...prevMember,
                [item_id]: item.value,
            })) : 
            setMemberToUpdate((prevMember: Member) => ({
                ...prevMember,
                [item_id]: item.value,
            }))
    }

    function handleSelectRole(e: any) {
        newMember ? 
            setMember((prevMember: Member) => ({
                ...prevMember,
                "role": e.value,
            })) : 
            setMemberToUpdate((prevMember: Member) => ({
                ...prevMember,
                "role": e.value,
            }))
        
    }

    async function handleInviteUser(){
        const memberdict = {
            "email": member.email,
            "role": member.role,
            "first_name": member.first_name,
            "last_name": member.last_name
        }
        if(keycloak.token && org_id) {
            await inviteOrganizationMember(keycloak.token, org_id, memberdict);
            toast.success('Invitation sent.', {
                position: toast.POSITION.TOP_RIGHT
            });
            onToggleModal();
        }
    }

    async function handleSaveUser() {
        if(keycloak.token && org_id && memberToUpdate.id){
            await saveUserDetails(keycloak.token, org_id, memberToUpdate.id.toString(), memberToUpdate)
            toast.success('Member successfully updated', {
                position: toast.POSITION.TOP_RIGHT
            });
            onToggleModal();
        }  
    }

    const toggleModalVisibility: React.MouseEventHandler<HTMLButtonElement> = () => {
        onToggleModal();
    }


    return (
        <MemberModal 
            member={member}
            existingMember={memberToUpdate}
            onChange={handleChange}
            onSelectRole={handleSelectRole}
            onToggleModal={toggleModalVisibility}
            onSave={newMember ? handleInviteUser : handleSaveUser}
            newMember={newMember}
            currentMember={currentMember}
            superuser={superuser}
        />
    )
}